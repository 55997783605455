import axios from 'axios';

const httpClient = axios.create({
  baseURL: '/api',
  timeout: 10000,
  withCredentials: true,
});

// Setup axios
httpClient.defaults.withCredentials = true;
httpClient.interceptors.response.use(null, err => Promise.reject(err));

export default httpClient;
