import logoLogin from './vault-logo-white.svg';
import logoBlock from './vault-logo.svg';
import featureBg from './feature_bg.png';
import Badge from './badge/web-portal-badge.png';
import Badge2x from './badge/web-portal-badge@2x.png';
import Badge3x from './badge/web-portal-badge@3x.png';
import logo from './logo.png';

export default {
  logoLogin,
  logoBlock,
  featureBg,
  favicon: logo,
  Badge,
  Badge2x,
  Badge3x,
};
