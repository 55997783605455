import React, { lazy, Suspense, useContext } from 'react';
import { useSelector } from 'react-redux';
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom';
import Layout from '../components/layout';
import { TermsPage } from '../components/pages/Auth/Register/Terms';
import Loader from '../components/shared/Loader';
import { BrandContext } from 'app/brandcontext/brandContext';
import { VerifySmsProvider } from 'components/pages/Settings/VerifySmsContext/VerifySmsContext';

// App routes
const NotFoundPage = lazy(() => import('../components/pages/NotFound'));
const Locations = lazy(() => import('../components/pages/Locations'));
const MyLockers = lazy(() => import('../components/pages/MyDeliveries'));
const Settings = lazy(() => import('../components/pages/Settings'));
const QrCodes = lazy(() => import('../components/pages/QrCodes'));

// Auth routes
const Login = lazy(() => import('../components/pages/Auth/Login'));
const Register = lazy(() => import('../components/pages/Auth/Register'));
const RecoverPassword = lazy(() =>
  import('../components/pages/Auth/ResetPasswordEmail')
);
const ResetPassword = lazy(() =>
  import('../components/pages/Auth/ResetPassword')
);
const Confirmation = lazy(() =>
  import('../components/pages/Auth/Confirmation')
);
const EmailTermsAccepted = lazy(() =>
  import('../components/pages/Auth/EmailTermsAccepted')
);
const GenerateTanQr = lazy(() =>
  import('../components/pages/Auth/GenerateTanQr')
);
const InviteFlow = lazy(() => import('../components/pages/Auth/InviteFlow'));

// private routes require login
const PrivateRoute = ({ component: Component, ...rest }) => {
  const { isAuthenticated, current } = useSelector(state => state.auth);
  let authComponent = (
    <Layout>
      <Component />
    </Layout>
  );
  if (current?.emailCode) authComponent = <Confirmation />;
  if (!current?.hasAcceptedTerms) authComponent = <InviteFlow />;
  return (
    <Route
      {...rest}
      render={props => (
        <Suspense fallback={<Loader />}>
          {isAuthenticated ? authComponent : <Login />}
        </Suspense>
      )}
    />
  );
};

// public routes do not require login, redirect to home page if already logged in
const PublicRoute = ({
  component: Component,
  authRedirect,
  exact,
  path,
  ...rest
}) => {
  const { brandName } = useContext(BrandContext);
  const { isAuthenticated } = useSelector(state => state.auth);
  return (
    <Route
      exact={exact}
      path={path}
      render={props => (
        <Suspense fallback={<Loader />}>
          {isAuthenticated ? (
            <Redirect to={authRedirect || `/${brandName}`} />
          ) : (
            <Component {...rest} {...props} />
          )}
        </Suspense>
      )}
    />
  );
};

export const Routes = () => (
  <Router>
    <Switch>
      {/* public routes */}
      <PublicRoute exact path="/" component={Login} />
      <PublicRoute exact path="/en/:brandname" component={Login} lang="en" />
      <PublicRoute exact path="/de/:brandname" component={Login} lang="de" />
      <PublicRoute exact path="/terms/:brandname" component={TermsPage} />
      <PublicRoute exact path="/terms/:lang/:brandname" component={TermsPage} />
      <PublicRoute
        exact
        path="/user-terms/:userCode/:brandname"
        component={TermsPage}
      />
      <PublicRoute
        exact
        path="/user-terms-accepted/:brandname"
        component={EmailTermsAccepted}
      />
      <PublicRoute exact path="/register" component={Register} />
      <PublicRoute exact path="/register/:brandname" component={Register} />
      <PublicRoute exact path="/confirm-email" component={Confirmation} />
      <PublicRoute
        exact
        path="/confirm-email/:brandname"
        component={Confirmation}
      />
      <PublicRoute
        exact
        path="/locker-tan"
        component={GenerateTanQr}
        authRedirect={'/my-deliveries/:brandname'}
      />
      <PublicRoute
        exact
        path="/locker-tan/:brandname"
        component={GenerateTanQr}
        authRedirect={'/my-deliveries/:brandname'}
      />
      <PublicRoute
        exact
        path="/recover-password/:brandname"
        component={RecoverPassword}
      />
      <PublicRoute exact path="/reset-password" component={ResetPassword} />
      <PublicRoute
        exact
        path="/reset-password/:brandname"
        component={ResetPassword}
      />
      {/* private routes */}
      <PrivateRoute exact path="/:brandname" component={Locations} />
      <PrivateRoute exact path="/locations/:brandname" component={Locations} />
      <PrivateRoute
        exact
        path="/my-deliveries/:brandname"
        component={MyLockers}
      />
      <PrivateRoute exact path="/qr-codes/:brandname" component={QrCodes} />
      <VerifySmsProvider>
        <PrivateRoute exact path="/settings/:brandname" component={Settings} />
      </VerifySmsProvider>
      {/* 404 error route */}
      <Route
        render={() => (
          <Suspense fallback={<Loader />}>
            <NotFoundPage />
          </Suspense>
        )}
      />
    </Switch>
  </Router>
);
