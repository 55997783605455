import { css } from 'styled-components';

export default css`
  body {
    font-family: 'Helvetica Neue', -apple-system, BlinkMacSystemFont, 'Segoe UI',
      'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
      'Helvetica Neue', sans-serif;
    background: linear-gradient(#8ecf69, #476835); 
    font-size: 15px;
  }

  /* override webkit autofill styling */
  input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 50px #606060 inset;
    -webkit-text-fill-color: #fff;
  }

  input:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0 50px #606060 inset;
    -webkit-text-fill-color: #fff;
  }
`;
