import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import brands from 'brands';
import TopMenu from './TopMenu';
import { BrandContext } from 'app/brandcontext/brandContext';

import { Container } from './Topbar.styled';

const Topbar = ({ withMenu = true, onToggleDrawer }) => {
  const { brandName } = useContext(BrandContext);
  const { logoLogin } = brands[brandName];

  return (
    <Container>
      <Link to={`/${brandName}`}>
        <img src={logoLogin} alt={"Login logo"} style={{ width: 230 }} />
      </Link>
      {withMenu && <TopMenu onToggleDrawer={onToggleDrawer} />}
    </Container>
  );
};

export default Topbar;
