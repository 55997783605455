import { CALL_API } from 'app/apiMiddleware';

export const commonInitialState = {
  fetching: false,
  modifying: false,
  deleting: false,
  error: null,
  entities: [],
  current: {},
  total: null,
};

export const commonReducers = {
  fetchRequest(state, action) {
    state.fetching = true;
  },
  fetchAllSuccess(state, action) {
    state.fetching = false;
    state.entities = action.payload.data;
  },
  fetchOneSuccess(state, action) {
    state.fetching = false;
    state.current = action.payload;
  },
  fetchError(state, action) {
    state.fetching = false;
    state.error = action.payload;
  },
  clearEntity(state, action) {
    state.fetching = false;
    state.current = {};
  },
  modifyRequest(state, action) {
    state.modifying = true;
  },
  modifySuccess(state, action) {
    state.modifying = false;
    const newEntity = action.payload;
    if (!newEntity) return;
    const index = state.entities.findIndex(
      entity => entity.id === newEntity.id
    );

    if (index === -1) {
      // this is a create action (add newest entry to the start)
      state.entities.unshift(newEntity);
    } else {
      // this is a modify action (replace)
      state.entities[index] = newEntity;
    }
  },
  modifyError(state, action) {
    state.modifying = false;
  },
  deleteRequest(state, action) {
    state.deleting = true;
  },
  deleteSuccess(state, action) {
    state.deleting = false;
    if (action.payload?.id) {
      const deleteIndex = state.entities.findIndex(
        e => e.id === action.payload.id
      );
      state.entities.splice(deleteIndex, 1);
    }
  },
  deleteError(state, action) {
    state.deleting = false;
  },
};

// thunks will take a successMessage and errorMessage
// these are displayed as notifications and can be passed to the fn once translated
export const createCommonThunks = (namespace, endpoint) => {
  const fetchAll = (reqBody = { take: 9999 }) => ({
    [CALL_API]: {
      types: [
        `${namespace}/fetchRequest`,
        `${namespace}/fetchAllSuccess`,
        `${namespace}/fetchError`,
      ],
      promiseFn: httpClient => httpClient.post(endpoint, reqBody),
    },
  });

  const fetchOne = id => (dispatch, getState) => {
    const entityState = getState()[namespace];

    if (entityState.entities && id in entityState.entities) {
      // entity already exists in store
      dispatch({
        type: `${namespace}/fetchOneSuccess`,
        payload: entityState.entities[id],
      });
    } else {
      dispatch({
        [CALL_API]: {
          types: [
            `${namespace}/fetchRequest`,
            `${namespace}/fetchOneSuccess`,
            `${namespace}/fetchError`,
          ],
          promiseFn: httpClient => httpClient.get(`${endpoint}/${id}`),
        },
      });
    }
  };

  // this is just here to collocate with other fetchy thunks
  const clearEntity = () => ({
    type: `${namespace}/clearEntity`,
  });

  const createEntity = ({
    entity,
    errorMessage,
    successMessage,
    onSuccess,
    onError,
  }) => ({
    [CALL_API]: {
      types: [
        `${namespace}/modifyRequest`,
        `${namespace}/modifySuccess`,
        `${namespace}/modifyError`,
      ],
      promiseFn: httpClient => httpClient.post(`${endpoint}/modify`, entity),
      errorMessage: errorMessage || 'There was a problem creating the entity',
      successMessage: successMessage || 'Entity successfully created',
      onSuccess: onSuccess || null,
      onError: onError || null,
    },
  });

  const modifyEntity = ({
    entity,
    errorMessage,
    successMessage,
    onSuccess,
    onError,
  }) => ({
    [CALL_API]: {
      types: [
        `${namespace}/modifyRequest`,
        `${namespace}/modifySuccess`,
        `${namespace}/modifyError`,
      ],
      promiseFn: httpClient => httpClient.post(`${endpoint}/modify`, entity),
      errorMessage: errorMessage || 'There was a problem modifying the entity',
      successMessage: successMessage || 'Entity successfully modified',
      onSuccess: onSuccess || null,
      onError: onError || null,
    },
  });

  const deleteEntity = ({
    id,
    errorMessage,
    successMessage,
    onSuccess,
    onError,
  }) => ({
    [CALL_API]: {
      types: [
        `${namespace}/deleteRequest`,
        `${namespace}/deleteSuccess`,
        `${namespace}/deleteError`,
      ],
      promiseFn: httpClient => httpClient.delete(`${endpoint}/${id}/delete`),
      errorMessage: errorMessage || 'There was a problem deleting the entity',
      successMessage: successMessage || 'Entity succesfully deleted',
      onSuccess: onSuccess || null,
      onError: onError || null,
    },
  });

  return {
    fetchAll,
    fetchOne,
    clearEntity,
    createEntity,
    modifyEntity,
    deleteEntity,
  };
};
