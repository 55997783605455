import { createMuiTheme } from '@material-ui/core';

// TODO: define multiple themes here the user can choose from settings

// refresh button and coloured buttons
export const primary1Color = {
  main: '#E93947',
  contrastText: '#fff',
};
// subtitle backgrounds - should be darker
export const primary2Color = {
  main: '#8C8C8C',
  contrastText: '#fff',
};

const theme = createMuiTheme({
  palette: {
    primary: primary1Color,
    secondary: primary2Color,
    background: {
      paper: '#fff',
      default: '#4C4C4C',
    },
    type: 'light',
  },
  typography: {
    fontFamily: ['Roboto', 'Helvetica', 'Arial', 'sans-serif'].join(','),
    fontSize: 14,
  },
  shape: {
    borderRadius: '11px',
  },
  overrides: {
    MuiInput: {
      input: {
        padding: '0.5rem 1rem',
      },
      underline: {
        '&:before': {
          borderBottom: `1px solid #7B7D81`,
        },
      },
    },
    MuiInputLabel: {
      root: {
        color: primary2Color.main,
      },
    },
    MuiLink: {
      root: {
        color: '#242934',
      },
    },
    MuiButton: {
      root: {
        minWidth: 108,
        height: 29,
        padding: '0 1rem',
        borderRadius: '5px',
      },
      outlined: {
        padding: '0 1rem',
      },
      text: {
        padding: '0 8px',
        whiteSpace: 'nowrap',
      },
      label: {
        whiteSpace: 'nowrap',
      },
    },
    MuiPaper: {
      root: {
        border: 'solid 1px #7B7D81',
      },
      elevation1: {
        boxShadow: '4px 8px 20px 0 rgba(0, 0, 0, 0.26)',
      },
    },
    MuiTabs: {
      root: {
        minHeight: 43,
      },
    },
    MuiTab: {
      root: {
        // width: 260,
        height: 43,
        minHeight: 43,
        padding: '0 24px 0 32px',
        borderRadius: '11px 11px 0 0',
        opacity: 1,
        background: primary2Color.main,
        color: '#25364d',
        border: `1px solid #7B7D81`,
        letterSpacing: 1.95,
        whiteSpace: 'nowrap',
      },
      wrapper: {
        // fontSize: 20,
        // fontWeight: 600,
        color: primary2Color.contrastText,
      },
      textColorInherit: {
        opacity: 1,
        '&:disabled': {
          opacity: 1,
          background: '#25364D',
        },
      },
    },
    // TABLE STYLES
    MuiTable: {
      root: {
        color: '#fff',
      },
    },
    MuiTableCell: {
      root: {
        borderBottom: 0,
        padding: '2px 12px',
        fontWeight: 200,
        // fontSize: 18,
      },
      head: {
        // fontSize: 18,
        fontWeight: 600,
        padding: '12px 12px',
      },
    },
    MuiTablePagination: {
      root: {
        padding: 100,
      },
    },
    MuiToolbar: {
      root: {
        padding: 0,
      },
    },
  },
});

export default theme;
