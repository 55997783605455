import brands from 'brands';
import { css } from 'styled-components';
import 'typeface-open-sans';

const { featureBg } = brands.schlauebox;

export default css`
  body {
    font-family: 'Open Sans', 'Roboto', 'Helvetica', 'Arial', sans-serif;
    background: url('${featureBg}');
    background-size: cover;
    background-attachment: fixed;
    color: #0c121e;
  }
  /* override webkit autofill styling */
  input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 50px #b2b2b2 inset;
    -webkit-text-fill-color: #fff;
  }

  input:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0 50px #b2b2b2 inset;
    -webkit-text-fill-color: #fff;
  }
`;
