import { createSlice } from '@reduxjs/toolkit';
import { CALL_API } from 'app/apiMiddleware';
import {
  commonInitialState,
  commonReducers,
  // createCommonThunks,
} from '../commonSlice';

const initialState = {
  ...commonInitialState,
};

const deliveries = createSlice({
  name: 'deliveries',
  initialState,
  reducers: {
    ...commonReducers,
    fetchRequest: state => {
      state.fetching = true;
    },
    fetchError: state => {
      state.fetching = true;
    },
    fetchAllSuccess: (state, action) => {
      state.fetching = false;
      state.entities = action.payload;
    },
  },
});

export const { actions } = deliveries;

export default deliveries.reducer;

// Operations
// const thunks = createCommonThunks('deliveries', '/deliveries');

// export const {
//   fetchAll,
//   fetchOne,
//   createEntity,
//   modifyEntity,
//   deleteEntity,
// } = thunks;

export const fetchDeliveries = () => ({
  [CALL_API]: {
    types: [
      `deliveries/fetchRequest`,
      `deliveries/fetchAllSuccess`,
      `deliveries/fetchError`,
    ],
    promiseFn: httpClient => httpClient.get('/activeDeliverys'),
  },
});
