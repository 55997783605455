import React, { useEffect, useContext } from 'react';
import styled from 'styled-components';
import { Button, Link, Paper } from '@material-ui/core';
import { useTranslation, Trans } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import PageTitle from 'components/shared/PageTitle';
import Topbar from 'components/layout/Topbar';
import i18n from 'app/i18n';
import { BrandContext } from 'app/brandcontext/brandContext';
import httpClient from 'utilities/httpClient';
import { toast } from 'react-toastify';

const Subtitle = styled.p``;

const BodyText = styled.div`
  a {
    color: #fff;
    text-decoration: underline;
  }
`;

const OptionsArea = styled.div`
  width: 260px;
  display: flex;
  justify-content: space-between;
  margin: 40px auto 30px;
`;

const Terms = ({
  handleAcceptTerms,
  handleDecline,
  isDialog = false,
  userCode = false,
  onClose,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { language } = i18n;

  const { brandName } = useContext(BrandContext);

  // default navigate home on decline
  if (!handleDecline) handleDecline = () => history.push(`/${brandName}`); // eslint-disable-line no-param-reassign
  if (!onClose) onClose = () => history.push(`/${brandName}`); // eslint-disable-line no-param-reassign

  // default vars for slt
  let i18nKey = 'terms_and_conditions_flexicpl';
  let portalLink = 'https://flexilockercpl.swissinnolab.com';
  let locationsLink = `https://smartlockertec.com/${language}/flexilocker-cpl-locations`;
  let manualLink = `https://smartlockertec.com/flexilocker-cpl-guide/${
    language === 'de' ? 'de' : 'en'
  }`;

  if (brandName === 'smartboxbasel') {
    i18nKey = 'terms_and_conditions_cplbasel';
    portalLink = 'https://cityofbasel.swissinnolab.com';
    locationsLink = `https://smartlockertec.com/${language}/smartboxbasel-locations`;
    manualLink = `https://guide.smartboxbasel.ch/${
      language === 'de' ? 'de' : 'en'
    }/introduction`;
  }
  if (brandName === 'schlauebox') {
    i18nKey = 'terms_and_conditions_schlauebox';
  }
  if (brandName === 'vaultgroup') {
    i18nKey = 'terms_and_conditions_vaultgroup';
  }
  if (brandName === 'salubox') {
    i18nKey = 'terms_and_conditions_cplbasel';
  }

  return (
    <div style={{ padding: isDialog ? '1rem' : '' }}>
      <PageTitle>{t('Terms & Conditions')}</PageTitle>
      <Subtitle>
        {t('Please read our terms and conditions before proceeding')}:
      </Subtitle>
      <BodyText>
        <Trans
          i18nKey={i18nKey}
          portalLink={portalLink}
          locationsLink={locationsLink}
          manualLink={manualLink}
        >
          <h3>
            General Terms and Conditions for SmartBoxBasel CPL (City Parcel
            Locker)
          </h3>
          <h3>1. Area of validity and description of service</h3>
          <h4>
            1.1 Acceptance of the General Terms and Conditions for SmartBoxBasel
            CPL
          </h4>
          <p>
            The General Terms and Conditions for SmartBoxBasel CPL (hereinafter
            “GTC”) shall be deemed as accepted no later than with the use of the
            service by the Customer.
          </p>
          <h4>1.2 Scope of application and contract components</h4>
          <p>
            These GTC regulate the options for use of the SmartBoxBasel CPL
            services provided by Bau- und Verkehrsdepartement Basel-Stadt
            (hereinafter referred to as BV-BASEL) and / or its third party
            partners (hereinafter referred to as Partner) by the customers
            (hereinafter referred to as the Customer) for a specific
            SmartBoxBasel CPL point.
          </p>
          <h3>2. SmartBoxBasel CPL points and related GTC</h3>
          <h4>2.1. Location directory</h4>
          <p>
            The current location directory of all SmartBoxBasel CPL points is
            available on the Internet at locations.
          </p>
          <h4>2.2. GTC for a SmartBoxBasel CPL point</h4>
          <p>
            The SmartBoxBasel CPL services at a specific CPL point may be
            provided by different partners. The related GTC for a specific CPL
            point is available on the Internet at locations by clicking the GTC
            link for the specific CPL point.
          </p>
          <h3>3. SmartBoxBasel CPL Service</h3>
          <h4>3.1 User Manual</h4>
          <p>
            {/* // todo: nodes cannot be nested in translation strings, but even that won't work */}
            The SmartBoxBasel CPL user manual is available on the Internet at{' '}
            <Link href={manualLink}>{{ manualLink }}</Link> and forms an
            integral component of the agreement with BV-BASEL and Partner.
          </p>
          <h4>3.2. Registration</h4>
          <p>
            In order to be able to use the SmartBoxBasel CPL services, it is
            necessary to register at{' '}
            <Link href={portalLink}>{{ portalLink }}</Link> or via the
            SmartBoxBasel CPL User App. During the registration, the Customer
            will receive an email confirmation link which needs to be confirmed
            in order to finish registration.
          </p>
          <h4> 3.3. Selecting a location</h4>
          <p>
            The Customer has the option of freely selecting his SmartBoxBasel
            CPL point at any time. The SmartBoxBasel CPL address on the
            consignment is relevant for the delivery.
          </p>
          <h4>3.4. Addressing</h4>
          <p>
            Each consignment to be delivered via a SmartBoxBasel CPL point must
            be addressed as specified in the specific location directory.
          </p>
          <h4>3.5. Definition of a SmartBoxBasel CPL consignment</h4>
          <p>
            Unless otherwise defined for a specific SmartBoxBasel CPL point, all
            parcels up to 30 kg with a consignment number can be addressed to a
            SmartBoxBasel CPL and collected from there.
          </p>
          <h4>3.6. Collecting a consignment</h4>
          <p>
            For each consignment to be collected, the Customer is notified by
            e-mail and/or push notification. With the collection code contained
            in this and/or via the SmartBoxBasel CPL User App, the consignment
            can be released from the specific SmartBoxBasel CPL within the
            specified time limit (default is 72 hours). It is the responsibility
            of the Customer to ensure that the collection code received is not
            accessible to unwanted third parties.
          </p>
          <p>
            In the event of unavailable locker sizes or faults on the terminal,
            the consignment will not be delivered. A delivery to the requested
            SmartBoxBasel CPL location cannot be guaranteed.
          </p>
          <p>
            An email message will be sent to the Customer in case of
            non-collection of the consignment within the specified time limit.
            Access to the specific locker will then be provided to Partner who
            will collect and store the consignment.
          </p>
          <h3>4. Guarantee</h3>
          <p>
            The Customer guarantees the correctness of his personal details
            (name, address etc.) with the registration for the SmartBoxBasel CPL
            services.
          </p>
          <h3>5. Liability</h3>
          <p>
            BV-BASEL and Partners are not liable for damage resulting from
            incorrect details from the Customer. BV-BASEL and Partner in
            particular accepts no liability for undeliverable SMS messages,
            push-notifications or e-mails as a result of an incorrect mobile
            phone number or e-mail address. Customers are not informed about
            undeliverable notifications. BV-BASEL and Partner assumes no
            liability for the SMS messages, push-notifications and/or e-mails
            sent to a provider for forwarding if it could not be delivered to
            the Customer by the provider.
          </p>
          <p>
            BV-BASEL accepts no liability for the services provided by Partner.
          </p>
          <p>
            BV-BASEL is, in particular, not liable for force majeure,
            consequential damage and lost earnings.
          </p>
          <h3>6. Information to third parties</h3>
          <p>
            BV-BASEL and Partner can provide third parties with the Customer’s
            name and domicile address, provided said party can prove a
            legitimate interest therein.
          </p>
          <h3>7. Cancellation</h3>
          <p>
            Unless otherwise defined for a specific SmartBoxBasel CPL point,
            BV-BASEL has the option of cancelling the SmartBoxBasel CPL services
            for the Customer in writing within 30 days to the month-end.
          </p>
          <p>
            BV-BASEL is authorized to block the selected service at any time
            with immediate effect, if it is suspected that the range of services
            have been misused.
          </p>
          <h3>8. Amendments to the Terms and Conditions</h3>
          <p>
            BV-BASEL can revise these Terms and Conditions at any time. The
            revisions will be communicated to the Customer in writing or in
            another appropriate form. Changes will be deemed to have been
            accepted if the Customer uses the service despite these changes or
            if he does not object in writing within one month.
          </p>
          <h3> 9. Applicable law and place of jurisdiction</h3>
          <p>
            The legal relationships between the Customer using the SmartBoxBasel
            CPL and BV-BASEL and Partner are exclusively governed by Swiss law.
            The place of jurisdiction is Basel.
          </p>
          <h3>10. Contact</h3>
          <p>
            The contact for a specific CPL point is available on the Internet at{' '}
            <Link href={locationsLink}>{{ locationsLink }}</Link> by clicking
            the Contact link for the specific CPL point.
          </p>
        </Trans>
      </BodyText>
      <OptionsArea>
        {isDialog && !userCode ? (
          <Button
            variant="outlined"
            color="primary"
            onClick={onClose}
            style={{ margin: '0 auto' }}
          >
            {t('Close')}
          </Button>
        ) : (
          <>
            <Button variant="outlined" color="primary" onClick={handleDecline}>
              {t('Decline')}
            </Button>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              onClick={handleAcceptTerms}
            >
              {t('Accept')}
            </Button>
          </>
        )}
      </OptionsArea>
    </div>
  );
};

export default Terms;

const Container = styled.div`
  /* vp height - the height of the top bar */
  min-height: calc(100vh - 90px - 80px);
  display: flex;
  justify-content: center;
  align-items: center;
  /* padding-bottom: 80px; */
`;
const StyledPaper = styled(Paper)`
  max-width: 850px;
  margin: 0 20px 20px;
  padding: 25px;
`;

export const TermsPage = () => {
  const params = useParams();
  const history = useHistory();
  const { t } = useTranslation();
  const { brandName } = useContext(BrandContext);

  useEffect(() => {
    if (params?.lang) {
      const lang = params.lang.toLowerCase();
      if (lang === 'en' || lang === 'de') {
        i18n.changeLanguage(lang);
      }
    }
  }, [params]);

  const handleAcceptTerms = params?.userCode
    ? async () => {
        try {
          const response = await httpClient.put(
            `/endUser/acceptTermsWithCode/${params.userCode}`
          );
          if (response.status === 200 && response.data?.id)
            history.push(`/user-terms-accepted/${brandName}`);
          else throw new Error();
        } catch (err) {
          console.log(err);
          toast.error(t`There was a problem submitting with that email code`);
        }
      }
    : undefined;

  return (
    <>
      <Topbar withMenu={false} />
      <Container>
        <StyledPaper>
          <Terms
            isDialog
            userCode={params?.userCode}
            handleAcceptTerms={handleAcceptTerms}
          />
        </StyledPaper>
      </Container>
    </>
  );
};
