import styled from 'styled-components';

export const Container = styled.div`
  padding: 20px 0;
  margin: 0 auto;
  width: 90%;
  max-width: 1280px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
