import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { Provider } from 'react-redux';
import store from './app/store';
import App from './app/App';
import { BrowserRouter } from 'react-router-dom';
import { BrandProvider } from 'app/brandcontext/brandContext';
import './app/i18n';

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <BrandProvider>
        <App />
      </BrandProvider>
    </BrowserRouter>
  </Provider>,
  // create an element if it doesn't exist (for testing)
  document.getElementById('root') || document.createElement('div')
);
