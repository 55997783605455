import React, { useState, useContext } from 'react';
import { Dialog, IconButton } from '@material-ui/core';
import { MdClose } from 'react-icons/md';
import i18n from 'app/i18n';
import { useTranslation } from 'react-i18next';
import { BrandContext } from 'app/brandcontext/brandContext';

const surveyUrls = {
  flexicpl: 'https://flexilockers.com/survey/index.php/813213?newtest=Y&lang=',
};

const Survey = () => {
  const { brandName } = useContext(BrandContext);

  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();
  const onOpen = () => setIsOpen(true);
  const onClose = () => setIsOpen(false);
  return Object.keys(surveyUrls).includes(brandName) ? (
    <>
      <a href="#" className="survey-link" onClick={onOpen}>
        {t`Feedback`}
      </a>
      <Dialog open={isOpen} onClose={onClose} fullWidth maxWidth="md">
        <IconButton
          aria-label="close"
          onClick={onClose}
          style={{
            position: 'absolute',
            top: 0,
            right: 0,
            color: '#000',
          }}
        >
          <MdClose />
        </IconButton>
        <iframe
          title="survey"
          src={`${surveyUrls[brandName]}${i18n.language}`}
          width="100%"
          height="500"
        />
      </Dialog>
    </>
  ) : null;
};

export default Survey;
