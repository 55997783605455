import { createSlice } from '@reduxjs/toolkit';
import { CALL_API } from 'app/apiMiddleware';
import {
  commonInitialState,
  commonReducers,
  createCommonThunks,
} from '../commonSlice';

const initialState = {
  ...commonInitialState,
};

const languages = createSlice({
  name: 'languages',
  initialState,
  reducers: {
    ...commonReducers,
    fetchAllSuccess: (state, action) => {
      state.entities = action.payload;
    },
  },
});

export const { actions } = languages;

export default languages.reducer;

// Operations
const thunks = createCommonThunks('languages', '/language');

export const {
  fetchAll,
  fetchOne,
  createEntity,
  modifyEntity,
  deleteEntity,
} = thunks;

export const fetchLanguages = () => ({
  [CALL_API]: {
    types: [
      `languages/fetchRequest`,
      `languages/fetchAllSuccess`,
      `languages/fetchError`,
    ],
    promiseFn: httpClient => httpClient.get('/languages'),
  },
});
