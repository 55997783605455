import React, { createContext, useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import httpClient from 'utilities/httpClient';

export const VerifySmsContext = createContext({});

export const VerifySmsProvider = ({ children }) => {
  const { t } = useTranslation();

  const [isSendingSMSCode, setIsSendingSMSCode] = useState(false);

  const [isSmsSend, setIsSmsSend] = useState(false);
  const [counterTimer, setCounterTimer] = useState(60);
  const [finalSecondsPassed, setFinalSecondsPassed] = useState(60);

  const intervalId = useRef();

  const sendSMSVerificationCode = async () => {
    try {
      setFinalSecondsPassed(60);

      setIsSendingSMSCode(true);

      const smsResponse = await httpClient.patch(`endUser/phone/code/resend`);

      if (smsResponse.status === 200) setIsSmsSend(true);
    } catch (err) {
      toast.error(t('There was a problem sending sms code'));
      console.error(err);
    }
    setIsSendingSMSCode(false);
  };

  useEffect(() => {
    intervalId.current = setInterval(function() {
      // method to be executed;
      if (isSmsSend) {
        setCounterTimer(prev => prev - 1);
        setFinalSecondsPassed(prev => prev - 1);
      }
    }, 1000);

    return () => {
      if (counterTimer === 0) {
        setFinalSecondsPassed(60);
        setCounterTimer(60);
        setIsSmsSend(false);
        clearInterval(intervalId.current);
      }
    };
  }, [isSmsSend]);

  useEffect(() => {
    if (counterTimer === 0) {
      setCounterTimer(60);
      setIsSmsSend(false);
      clearInterval(intervalId.current);
    }
  }, [counterTimer]);

  return (
    <VerifySmsContext.Provider
      value={{
        isSendingSMSCode,
        setIsSendingSMSCode,
        isSmsSend,
        setIsSmsSend,
        counterTimer,
        setCounterTimer,
        finalSecondsPassed,
        setFinalSecondsPassed,
        sendSMSVerificationCode,
      }}
    >
      {children}
    </VerifySmsContext.Provider>
  );
};
