import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { CALL_API } from 'app/apiMiddleware';
import { toast } from 'react-toastify';
import httpClient from '../../utilities/httpClient';
import { commonInitialState, commonReducers } from '../commonSlice';

const initialState = {
  ...commonInitialState,
};

export const requestAccess = createAsyncThunk(
  'locations/requestAccess',
  async ({
    masterId,
    grantedMessage,
    pendingMessage,
    errorMessage,
    onSuccess,
  }) => {
    const response = await httpClient.put(
      `/accessRequest/master/${masterId}/create`
    );
    const result = response?.data?.result;
    if (result === 'PENDING' || result === 'GRANTED') {
      const message = result === 'GRANTED' ? grantedMessage : pendingMessage;
      toast.success(message);
      if (onSuccess)
        onSuccess({
          activeAccessRequest: result === 'PENDING',
          userIsAssigned: result === 'GRANTED',
        });
      return { result, masterId };
    }
    toast.error(errorMessage);
    return Promise.reject();
  }
);

const locations = createSlice({
  name: 'locations',
  initialState,
  reducers: {
    ...commonReducers,
    fetchRequest: state => {
      state.fetching = true;
    },
    fetchAllSuccess: (state, action) => {
      state.fetching = false;
      state.entities = action.payload;
    },
    fetchError: state => {
      state.fetching = true;
    },
    'requestAccess/fulfilled': (state, action) => {
      const thisRequest = {
        ...state.entities.find(loc => loc.id === action.payload.masterId),
      };
      if (action.payload.result === 'PENDING') {
        thisRequest.activeAccessRequest = true;
      } else if (action.payload.result === 'GRANTED') {
        thisRequest.userIsAssigned = true;
      }
      state.entities = [
        ...state.entities.filter(
          request => request.id !== action.payload.masterId
        ),
        thisRequest,
      ];
    },
  },
});

export const { actions } = locations;

export default locations.reducer;

// Operations
// const thunks = createCommonThunks('locations', '/locations');

// export const {
//   fetchAll,
//   fetchOne,
//   createEntity,
//   modifyEntity,
//   deleteEntity,
// } = thunks;

export const fetchLocations = () => ({
  [CALL_API]: {
    types: [
      `locations/fetchRequest`,
      `locations/fetchAllSuccess`,
      `locations/fetchError`,
    ],
    promiseFn: httpClient => httpClient.get('/locations'),
  },
});
