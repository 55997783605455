import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import i18n from 'app/i18n';
import { toast } from 'react-toastify';
import { CALL_API } from '../../app/apiMiddleware';
import httpClient from '../../utilities/httpClient';

const initialState = {
  isAuthenticating: false,
  isAuthenticated: false,
  error: null,
  current: null,
};

export const confirmMailCode = createAsyncThunk(
  'auth/mailCode',
  async ({ code, errorMessage, onSuccess, successMessage }) => {
    const response = await httpClient.put('/endUser/mailCode/confirm', {
      code,
    });
    if (response?.data?.response !== 'CONFIRMED') {
      toast.error(errorMessage);
      return Promise.reject();
    }
    toast.success(successMessage);
    onSuccess();
    const newCurrent = await httpClient.get('/endUser/info');
    return newCurrent.data;
  }
);

export const resendEmail = createAsyncThunk(
  'auth/resendEmail',
  async ({ errorMessage, successMessage }) => {
    const response = await httpClient
      .put('/confirmationEmail/resend')
      .then(res => res)
      .catch(err => err.response);
    console.log('New response is: ', response);
    if (response.status !== 200) {
      toast.error(errorMessage);
    } else {
      toast.success(successMessage);
    }
    return response.data;
  }
);

export const inviteFinalise = createAsyncThunk(
  'auth/inviteFinalise',
  async ({ oldPassword, newPassword, errorMessage, successMessage }) => {
    const acceptTermsResponse = await httpClient.get('/endUser/acceptTerms');
    if (acceptTermsResponse?.status !== 200) {
      toast.error(errorMessage);
    }
    const passwordResponse = await httpClient.post('/endUser/updatePassword', {
      oldPassword,
      newPassword,
    });
    if (passwordResponse?.status !== 200) {
      toast.error(errorMessage);
    }
    toast.success(successMessage);
    // create valid session with new creds
    httpClient.post('/login', {
      username: acceptTermsResponse.data.email,
      password: newPassword,
    });
    return acceptTermsResponse.data;
  }
);

export const login = createAsyncThunk(
  'auth/login',
  async (
    {
      languages,
      username,
      password,
      errorMessage,
      unauthorizedMessage,
      onUnconfirmed,
    },
    thunkAPI
  ) => {
    const response = await httpClient
      .post('/login', { username, password })
      .then(res => res)
      .catch(err => err.response);
    console.log(response);
    if (response.status === 401) {
      toast.error(unauthorizedMessage);
    } else if (response.status === 403) {
      onUnconfirmed();
    } else if (response.status !== 200) {
      toast.error(errorMessage);
    }

    if (response.status === 200) {
      const correctLang = languages.find(
        lang => lang.id === response.data.language___id
      );
      i18n.changeLanguage(correctLang?.code.toLowerCase());
    }

    if (!response.data.email)
      return Promise.reject(new Error('No user object'));
    return response.data;
  }
);

export const checkAuth = createAsyncThunk(
  'auth/checkAuth',
  async ({ unauthorizedMessage }, thunkAPI) => {
    const response = await httpClient.get('/check-auth');
    if (response.status === 401) {
      toast.warning(unauthorizedMessage);
      return Promise.reject();
    }
    return response.data;
  }
);

export const passwordRecover = createAsyncThunk(
  'auth/passwordRecover',
  async ({ identificator }) => {
    const response = await httpClient.get(
      `endUser/recoverPassword/${identificator}`
    );
    return response.status;
  }
);

export const logout = createAsyncThunk(
  'auth/logout',
  async ({ errorMessage, onSuccess, successMessage }, thunkAPI) => {
    const response = await httpClient.get('/logout');
    if (response.status !== 200) {
      toast.error(errorMessage);
      console.log(response);
    }
    if (response.status === 200) {
      toast.success(successMessage);
      onSuccess();
    }
  }
);

const auth = createSlice({
  name: 'auth',
  initialState,
  extraReducers: {
    [login.pending]: (state, action) => {
      state.isAuthenticating = true;
    },
    [login.rejected]: (state, action) => {
      state.isAuthenticated = false;
    },
    [login.fulfilled]: (state, action) => {
      state.isAuthenticating = false;
      state.isAuthenticated = true;
      state.current = action.payload;
    },
    [checkAuth.pending]: state => {
      state.isAuthenticating = true;
    },
    [checkAuth.rejected]: state => {
      state.isAuthenticating = false;
      state.isAuthenticated = false;
      state.current = null;
    },
    [checkAuth.fulfilled]: (state, action) => {
      state.isAuthenticating = false;
      state.isAuthenticated = true;
      state.current = action.payload;
    },
    [logout.fulfilled]: state => {
      state.isAuthenticating = false;
      state.isAuthenticated = false;
      state.current = null;
    },
    [confirmMailCode.fulfilled]: (state, action) => {
      state.current.status = 'CONFIRMED';
    },
    [resendEmail.fulfilled]: (state, action) => {
      state.current.status = 'CONFIRMED';
    },
    [inviteFinalise.fulfilled]: (state, action) => {
      state.current = action.payload;
    },
  },
});

export const { actions } = auth.actions;

export default auth.reducer;
