import styled from 'styled-components';

export default styled.h2`
  display: block;
  text-align: center;
  width: 260px;
  margin: 0 auto 20px;
  text-transform: uppercase;
  font-size: 22px;
  font-weight: 500;
  letter-spacing: 0.1em;
`;
