import { combineReducers } from '@reduxjs/toolkit';

import authReducer from 'features/auth/authSlice';
import locationsReducer from 'features/locations/locationsSlice';
import usersReducer from 'features/users/usersSlice';
import languagesReducer from 'features/languages/languagesSlice';
import deliveriesReducer from 'features/deliveries/deliveriesSlice';
import transactionsReducer from 'features/transactions/transactionsSlice';

const rootReducer = combineReducers({
  auth: authReducer,
  locations: locationsReducer,
  users: usersReducer,
  languages: languagesReducer,
  deliveries: deliveriesReducer,
  transactions: transactionsReducer,
});

export default rootReducer;
