import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { CircularProgress } from '@material-ui/core';

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  ${props => (props.button ? '' : `min-height: 300px;`)}
`;

const Loader = ({ button = false }) => (
  <Container button={button}>
    <CircularProgress size={button ? 20 : 40} />
  </Container>
);
Loader.propTypes = {
  button: PropTypes.bool,
};
export default Loader;
