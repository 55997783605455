import { createMuiTheme } from '@material-ui/core';

// TODO: define multiple themes here the user can choose from settings

const primary1Color = {
  main: '#606060',
  contrastText: '#fff',
};
const primary2Color = {
  main: '#8ecf69',
  contrastText: '#fff',
};

const theme = createMuiTheme({
  palette: {
    primary: primary1Color,
    secondary: primary2Color,
    text: {
      primary: primary1Color.main,
      secondary: primary2Color.main,
    },
    background: {
      paper: '#fff',
      default: '#0E2B4D',
    },
    type: 'light',
  },
  typography: {
    fontFamily: [
      '"Helvetica Neue"',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      'Arial',
      'sans-serif',
    ].join(','),
    fontSize: 16,
    fontWeight: 500,
  },
  shape: {
    borderRadius: '11px',
  },
  overrides: {
    MuiInput: {
      input: {
        padding: '0.5rem 1rem',
      },
      underline: {
        '&:before': {
          borderBottom: `1px solid #7B7D81`,
        },
      },
    },
    MuiInputLabel: {
      root: {
        color: '#606060',
      },
    },
    MuiButton: {
      root: {
        minWidth: 108,
        height: 29,
        padding: '0 1rem',
        borderRadius: '5px',
      },
      outlined: {
        padding: '0 1rem',
      },
      text: {
        padding: '0 8px',
        whiteSpace: 'nowrap',
      },
      label: {
        whiteSpace: 'nowrap',
      },
    },
    MuiPaper: {
      //root: {
        //border: 'solid 1px #7B7D81',
      //},
      elevation1: {
        boxShadow: '4px 8px 20px 0 rgba(0, 0, 0, 0.26)',
      },
    },
    MuiTabs: {
      root: {
        minHeight: 43,
      },
    },
    MuiTab: {
      root: {
        height: 43,
        minHeight: 43,
        padding: '0 24px 0 32px',
        borderRadius: '11px 11px 0 0',
        opacity: 1,
        background: '#fff',
        border: `1px solid #fff`,
        letterSpacing: 1.95,
        whiteSpace: 'nowrap',

      },
      // wrapper: {
      //   //color: primary1Color.contrastText
      // },
      textColorInherit: {
        opacity: 1,
        '&:disabled': {
          opacity: 1,
          background: '#25364D',
        },
      },
    },
    // TABLE STYLES
    MuiTable: {
      root: {
        color: '#fff',
      },
    },
    MuiTableCell: {
      root: {
        borderBottom: 0,
        padding: '2px 12px',
      },
      head: {
        fontWeight: 600,
        padding: '12px 12px',
      },
    },
    MuiTablePagination: {
      root: {
        padding: 100,
      },
    },
    MuiToolbar: {
      root: {
        padding: 0,
      },
    },
  },
});

export default theme;
