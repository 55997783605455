import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { MdWarning } from 'react-icons/md';

import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
} from '@material-ui/core';
import PageTitle from 'components/shared/PageTitle';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { confirmMailCode } from 'features/auth/authSlice';
import httpClient from 'utilities/httpClient';
import { toast } from 'react-toastify';

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: orange;
  color: #000;
  text-align: center;
  margin-bottom: 1rem;

  a {
    color: #000;
  }

  .unverified-warning-icon {
    display: inline-block;
    position: relative;
    font-size: 2rem;
    margin: 0.1rem 1rem;
  }
`;

const ResendLink = styled.button`
  display: block;
  margin: 0.2rem 2rem;
  color: rgba(0, 0, 0, 1);
  text-decoration: underline;
  text-align: left;
  background: none;
  border: none;
  cursor: pointer;
`;

export default function UnverifiedUser() {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [isFormOpen, setIsFormOpen] = useState(false);
  const [addressCode, setAddressCode] = useState('');
  const [canResend, setCanResend] = useState(false);
  const [isResendOpen, setIsResendOpen] = useState(false);

  const handleOpenForm = () => setIsFormOpen(true);
  const handleCloseForm = () => setIsFormOpen(false);

  const handleSubmit = () =>
    dispatch(
      confirmMailCode({
        code: addressCode,
        errorMessage: t('That email confirmation code is invalid.'),
        successMessage: t('You have successfully activated your account.'),
        onSuccess: handleCloseForm,
      })
    );

  //! mailcode info and resend are just being called here. No redux is really necessary at this point
  // fetch mail code info to check if it can be resent
  useEffect(() => {
    httpClient
      .get('/endUser/mailCode/info')
      .then(res => {
        const mailCodeInfo = res.data;
        const lastSent = new Date(
          mailCodeInfo.timeResent ||
            mailCodeInfo.timeSent ||
            mailCodeInfo.timeCreated
        );
        const timePeriod = 1000 * 60 * 60 * 72; // 72 hours
        if (new Date() - lastSent > timePeriod) setCanResend(true);
      })
      .catch(err => console.error(err));
  }, []);

  const handleResend = () =>
    httpClient
      .put('/endUser/mailCode/resend')
      .then(res => {
        toast.success(t('Your verification PIN will be resent'));
        setIsResendOpen(false);
      })
      .catch(err => console.error(err));

  return (
    <>
      <Container>
        <div className="unverified-warning-icon">
          <MdWarning />
        </div>
        <div>
          {t`unvertified.user`}
          {canResend ? (
            <ResendLink onClick={() => setIsResendOpen(true)}>
              {t(`Still haven't received a letter with your verification PIN?`)}
            </ResendLink>
          ) : (
            ''
          )}
        </div>
        <Button
          onClick={handleOpenForm}
          variant="outlined"
          style={{
            color: '#000',
            background: '#fff',
            margin: '0.2rem auto',
            height: 'auto',
            overflowWrap: 'anywhere',
          }}
        >
          <span style={{ whiteSpace: 'pre-wrap', }}>
            {t('Enter your activation code')}
          </span>
        </Button>
      </Container>
      {/* confirm code dialog */}
      <Dialog open={isFormOpen} onClose={handleCloseForm}>
        <div style={{ margin: '2rem 1rem 1rem' }}>
          <PageTitle>{t('Verify your address')}</PageTitle>
          <p
            style={{
              fontWeight: 400,
              opacity: 0.6,
              margin: '0 auto 1.5rem',
              textAlign: 'center',
              display: 'block',
              width: '80%',
            }}
          >
            {t(
              'Enter the code you receive at your physical address to verify your account'
            )}
          </p>
          <DialogContent>
            <TextField
              autofocus
              name="code"
              value={addressCode}
              onChange={e => setAddressCode(e.target.value)}
              fullWidth
              style={{ marginBottom: '1rem' }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseForm} color="primary">
              {t('Cancel')}
            </Button>
            <Button onClick={handleSubmit} color="primary" type="submit">
              {t('Submit')}
            </Button>
          </DialogActions>
        </div>
      </Dialog>
      {/* confirm resend dialog */}
      <Dialog open={isResendOpen} onClose={() => setIsResendOpen(false)}>
        <div
          style={{
            fontWeight: 400,
            opacity: 0.6,
            margin: '1rem auto 1.5rem',
            display: 'block',
            width: '90%',
            fontSize: '1rem',
          }}
        >
          {t('Are you sure you want to resend your verification PIN?')}
        </div>
        <DialogActions>
          <Button onClick={() => setIsResendOpen(false)} color="primary">
            {t('Cancel')}
          </Button>
          <Button onClick={handleResend} color="primary" type="submit">
            {t('Confirm')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
