import vaultgroupTheme from './themes/vaultgroupTheme';
import vaultgroupImages from './assets/images/brands/vaultgroup';

import smartboxbaselTheme from './themes/smartboxbaselTheme';
import smartboxbaselImages from './assets/images/brands/smartboxbasel';

import sltTheme from './themes/sltTheme';
import sltImages from './assets/images/brands/slt';

import schlaueboxTheme from './themes/schlaueboxTheme';
import schlaueboxImages from './assets/images/brands/schlauebox';

import saluboxTheme from './themes/saluboxTheme';
import saluboxImages from './assets/images/brands/salubox';

export const brandList = {
  SMARTBOXBASEL: 'smartboxbasel',
  SLT: 'slt',
  JELMOLI: 'jelmoli',
  SCHLAUEBOX: 'schlauebox',
  VAULTGROUP: 'vaultgroup',
  SALUBOX: 'salubox',
};

export default {
  smartboxbasel: {
    name: 'SmartBoxBasel',
    filteringCode: null,
    theme: smartboxbaselTheme,
    androidUrl:
      'https://play.google.com/store/apps/details?id=hr.sil.android.cpluser&hl=en',
    iosUrl: 'https://apps.apple.com/us/app/id1533947736',
    ...smartboxbaselImages,
  },
  slt: {
    name: 'SLT',
    filteringCode: null,
    theme: sltTheme,
    androidUrl:
      'https://play.google.com/store/apps/details?id=hr.sil.android.cplflexilockeruser',
    iosUrl: 'https://apps.apple.com/us/app/flexilocker-cpl/id1525369183',
    ...sltImages,
  },
  jelmoli: {
    name: 'Jelmoli',
    filteringCode: null,
    theme: sltTheme,
    androidUrl:
      'https://play.google.com/store/apps/details?id=hr.sil.android.cplflexilockeruser',
    iosUrl: 'https://apps.apple.com/us/app/flexilocker-cpl/id1525369183',
    ...sltImages,
  },
  schlauebox: {
    name: 'Schlauebox',
    filteringCode: null,
    theme: schlaueboxTheme,
    androidUrl:
      'https://play.google.com/store/apps/details?id=hr.sil.android.cplflexilockeruser',
    iosUrl: 'https://apps.apple.com/us/app/flexilocker-cpl/id1525369183',
    ...schlaueboxImages,
  },
  vaultgroup: {
    name: 'Vaultgroup',
    filteringCode: 'vaultgroup',
    theme: vaultgroupTheme,
    androidUrl:
      'https://play.google.com/store/apps/details?id=hr.sil.android.cplflexilockeruser',
    iosUrl: 'https://apps.apple.com/us/app/flexilocker-cpl/id1525369183',
    ...vaultgroupImages,
  },
  salubox: {
    name: 'SalüBox',
    filteringCode: 'salubox',
    theme: saluboxTheme,
    androidUrl:
      'https://play.google.com/store/apps/details?id=hr.sil.android.cplflexilockeruser',
    iosUrl: 'https://apps.apple.com/us/app/flexilocker-cpl/id1525369183',
    ...saluboxImages,
  },
};
