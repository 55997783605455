import { createSlice } from '@reduxjs/toolkit';
import { CALL_API } from '../../app/apiMiddleware';

import {
  commonInitialState,
  commonReducers,
  createCommonThunks,
} from '../commonSlice';

const initialState = {
  ...commonInitialState,
};

const users = createSlice({
  name: 'users',
  initialState,
  reducers: { ...commonReducers },
});

export const { actions } = users;

export default users.reducer;

// Operations
const thunks = createCommonThunks('users', '/endUser');

export const {
  fetchAll,
  fetchOne,
  createEntity,
  modifyEntity,
  deleteEntity,
} = thunks;

export function fetchCurrentUser() {
  return {
    [CALL_API]: {
      types: [
        `users/fetchRequest`,
        `users/fetchOneSuccess`,
        `users/fetchError`,
      ],
      promiseFn: httpClient => httpClient.get('endUser/info'),
    },
  };
}

export function registerUser({
  user,
  errorMessage,
  onSuccess,
  onError,
  requestErrorMessage,
}) {
  return {
    [CALL_API]: {
      types: [
        `users/modifyRequest`,
        `users/modifySuccess`,
        `users/modifyError`,
      ],
      promiseFn: httpClient => httpClient.post(`endUser/register`, user),
      errorMessage: errorMessage || 'There was a problem registering',
      requestErrorMessage:
        requestErrorMessage ||
        'There was a problem registering — please contact an administrator',
      onSuccess: onSuccess || null,
      onError: onError || null,
    },
  };
}

export function updateUser({
  user,
  successMessage,
  errorMessage,
  onSuccess,
  onError,
}) {
  return {
    [CALL_API]: {
      types: [
        `users/modifyRequest`,
        `users/modifySuccess`,
        `users/modifyError`,
      ],
      promiseFn: httpClient => httpClient.put(`endUser/update`, user),
      successMessage: successMessage || 'Successfully updated',
      errorMessage:
        errorMessage || 'There was a problem updating your settings',
      onSuccess: onSuccess || null,
      onError: onError || null,
    },
  };
}

export function updateAddress({
  address,
  successMessage = 'Successfully updated',
  errorMessage = 'There was a problem updating your address',
  onSuccess,
  onError,
}) {
  return {
    [CALL_API]: {
      types: [
        `users/modifyRequest`,
        `users/modifySuccess`,
        `users/modifyError`,
      ],
      promiseFn: httpClient =>
        httpClient.put(`endUser/address/update`, address),
      successMessage,
      errorMessage,
      onSuccess,
      onError,
    },
  };
}
