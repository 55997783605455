import { createSlice } from '@reduxjs/toolkit';
import { CALL_API } from 'app/apiMiddleware';
import {
  commonInitialState,
  commonReducers,
  // commonReducers,
  // createCommonThunks,
} from '../commonSlice';

const initialState = {
  ...commonInitialState,
};

const transactions = createSlice({
  name: 'transactions',
  initialState,
  reducers: {
    ...commonReducers,
  },
});

export const { actions } = transactions;

export default transactions.reducer;

// Operations
// const thunks = createCommonThunks('transactions', '/transactions');

// export const {
//   fetchAll,
//   fetchOne,
//   createEntity,
//   modifyEntity,
//   deleteEntity,
// } = thunks;

// todo: fetch (5?) most recent transactions
export const fetchTransactions = () => ({
  [CALL_API]: {
    types: [
      `transactions/fetchRequest`,
      `transactions/fetchAllSuccess`,
      `transactions/fetchError`,
    ],
    promiseFn: httpClient =>
      httpClient.post('/transactionAction/find', {
        sort: [
          {
            dir: 'desc',
            field: 'timeCreated',
          },
        ],
        skip: 0,
        take: 999,
      }),
  },
});

export const createTransaction = ({
  transaction,
  errorMessage,
  successMessage,
  onSuccess,
}) => ({
  [CALL_API]: {
    types: [
      `transactions/modifyRequest`,
      `transactions/modifySuccess`,
      `transactions/modifyError`,
    ],
    promiseFn: httpClient =>
      httpClient.post('/transactionAction/create', transaction),
    errorMessage,
    successMessage,
    onSuccess,
  },
});

export const deleteTransaction = ({
  transactionId,
  errorMessage,
  successMessage,
  onSuccess,
}) => ({
  [CALL_API]: {
    types: [
      `transactions/deleteRequest`,
      `transactions/deleteSuccess`,
      `transactions/deleteError`,
    ],
    promiseFn: httpClient =>
      httpClient.put(`/transactionAction/${transactionId}/markAsDeleted`),
    errorMessage,
    successMessage,
    onSuccess,
  },
});
