import React, { createContext, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import brands from 'brands';

import httpClient from 'utilities/httpClient';

export const BrandContext = createContext({});

const defaultBrand = process.env.REACT_APP_PROJECT;

export const BrandProvider = ({ children }) => {
  const location = useLocation();

  const [brandName, setBrandName] = useState('slt');

  useEffect(() => {
    if (location.pathname.includes(brandName)) return;
    let target = defaultBrand;

    const brandNames = Object.keys(brands);
    brandNames.forEach(brandName => {
      if (location.pathname.includes(brandName)) {
        target = brandName;
      }
    });
    // THIS IS DEFAULT THEME
    if(target === 'flexicpl') target = 'slt';

    httpClient.interceptors.request.use(
      config => {
        const filteringCode = brands[target].filteringCode;
        if (filteringCode) config.headers['brandname'] = filteringCode;
        return config;
      },
      err => {
        return Promise.reject(err);
      }
    );

    setBrandName(target);
  }, [location.pathname]);

  return (
    <BrandContext.Provider
      value={{
        logo: brands[brandName].logo,
        defaultLanguage: brands[brandName].defaultLanguage,
        languages: brands[brandName].languages,
        brandName,
        setBrandName,
      }}
    >
      {children}
    </BrandContext.Provider>
  );
};
