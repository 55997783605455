import SaluLogo from './salu-logo.svg';
import logoLogin from './salubox-logo.svg';
import HelveticaMedium from './fonts/HelveticaNeueLTPro65Medium.otf';
import HelveticaHeavy from './fonts/HelveticaNeueLTPro85Heavy.otf';
import Badge from './badge/web-portal-badge.png';
import Badge2x from './badge/web-portal-badge2x.png';
import Badge3x from './badge/web-portal-badge3x.png';

export default {
  SaluLogo,
  logoLogin,
  HelveticaMedium,
  HelveticaHeavy,
  Badge,
  Badge2x,
  Badge3x,
};