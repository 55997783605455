import smartboxbasel from './smartboxbasel';
import slt from './slt';
import schlauebox from './schlauebox';
import vaultgroup from './vaultgroup';
import salubox from './salubox';

export default {
  smartboxbasel,
  slt,
  schlauebox,
  vaultgroup,
  salubox
};
